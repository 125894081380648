import React from 'react';
import {
  Window,
  WindowContent,
  WindowHeader,
  Anchor,
  Frame,
  Tabs,
  Tab,
  TabBody,
  Fieldset,
} from 'react95';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import original from 'react95/dist/themes/original';
import ms_sans_serif from 'react95/dist/fonts/ms_sans_serif.woff2';
import ms_sans_serif_bold from 'react95/dist/fonts/ms_sans_serif_bold.woff2';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body {
    font-family: 'ms_sans_serif';
    background-color: teal;
  }

  .footer {
    display: block;
    margin: 0.25rem;
    height: 31px;
    line-height: 31px;
    padding-left: 0.25rem;
  }
`;

function MichinsoSolutions() {
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <div style={{ padding: '10px', maxWidth: '800px', margin: '0 auto' }}>
      <Window>
        <WindowHeader>
          <span>Michinso Solutions - Software Development Workshop</span>
        </WindowHeader>
        <WindowContent>
          <Tabs value={activeTab} onChange={setActiveTab}>
            <Tab value={0}>About Us</Tab>
            <Tab value={1}>Services</Tab>
            <Tab value={2}>Case Studies</Tab>
            <Tab value={3}>Contact Us</Tab>
          </Tabs>

          <TabBody>
            {activeTab === 0 && (
              <p>
                Welcome to Michinso Solutions, where innovation meets
                expertise. We are a team of passionate developers dedicated to
                delivering top-notch software solutions that drive business
                success. With years of experience across multiple industries,
                we specialize in crafting custom software that solves complex
                challenges and empowers businesses to thrive in the digital
                age.
              </p>
            )}
            {activeTab === 1 && (
              <>
                <p>
                  At Michinso Solutions, we offer a comprehensive range of
                  services that cater to all your software development needs:
                </p>
                <ul>
                  <li>
                    <strong>Full-Stack Web Development:</strong> From front-end
                    to back-end, we build robust, scalable, and secure web
                    applications using technologies like JavaScript, .NET/C#,
                    Ruby on Rails, NodeJS, and more.
                  </li>
                  <li>
                    <strong>Cloud Solutions:</strong> Leverage the power of
                    cloud computing with our expertise in AWS and Azure to
                    ensure your applications are reliable, scalable, and
                    cost-effective.
                  </li>
                  <li>
                    <strong>Mobile App Development:</strong> We create intuitive
                    and engaging mobile applications for both Android and iOS
                    platforms, ensuring a seamless user experience.
                  </li>
                  <li>
                    <strong>DevOps & CI/CD:</strong> Our DevOps services
                    streamline your software development lifecycle, improving
                    efficiency, collaboration, and deployment speed.
                  </li>
                  <li>
                    <strong>Consulting Services:</strong> Need guidance on
                    technology choices or architecture? Our experts provide
                    strategic consulting to help you make informed decisions.
                  </li>
                </ul>
              </>
            )}
            {activeTab === 2 && (
              <>
                <br />
                <article>
                  <Fieldset label={<h3>Enterprise Management System for a Global Manufacturing Leader</h3>}>
                    <p>
                      A Fortune 500 manufacturing company was struggling with
                      fragmented and outdated legacy systems across its global
                      operations. Michinso Solutions developed a custom enterprise
                      management system using .NET/C#, SQL Server, and Angular. The
                      solution integrated with the company's existing
                      infrastructure, providing real-time data synchronization
                      across multiple departments. The implementation resulted in
                      a 35% improvement in operational efficiency and a 25%
                      reduction in costs related to data errors.
                    </p>
                  </Fieldset>
                </article>
                <br />
                <br />
                <article>
                  <Fieldset label={<h3>E-Commerce Platform Migration and Optimization for a Retail Giant</h3>}>
                    <p>
                      A leading online retailer faced scalability issues and
                      frequent downtimes during peak sales periods. Michinso
                      Solutions migrated the entire e-commerce platform to AWS and
                      re-architected it using microservices with Node.js and
                      React. The result was 99.99% uptime during peak sales events,
                      a 40% increase in transaction speed, and a 20% reduction in
                      operational costs.
                    </p>
                  </Fieldset>
                </article>
                <br />
                <br />
                <article>
                  <Fieldset label={<h3>AI-Powered Customer Support for a Fintech Innovator</h3>}>
                    <p>
                      A fintech startup was experiencing rapid growth, but their
                      customer support team was overwhelmed. Michinso Solutions
                      developed an AI-powered chatbot using Python and TensorFlow,
                      which handled 60% of incoming queries, reduced response
                      times by 50%, and saved the company over $200,000 annually
                      in support costs.
                    </p>
                  </Fieldset>
                </article>
              </>
            )}
            {activeTab === 3 && (
              <>
                <p>
                  Ready to take your project to the next level? Get in touch
                  with us:
                </p>
                <address>
                  <strong>Michinso Solutions</strong>
                  <br />
                  Kyanja Central A, Kyanja, Nakawa Division
                  <br />
                  Kampala, Uganda
                  <br />
                  Email:{' '}
                  <Anchor href="mailto:invoicing@michinso.org">
                    invoicing@michinso.org
                  </Anchor>
                  <br />
                </address>
              </>
            )}
          </TabBody>
        </WindowContent>

        <Frame variant="well" className="footer">
          Copyright &copy; 2024 Michinso Solutions
        </Frame>
      </Window>
    </div>
  );
}

function App() {
  return (
    <ThemeProvider theme={original}>
      <GlobalStyle />
      <MichinsoSolutions />
    </ThemeProvider>
  );
}

export default App;
